<template>
  <div class="page inPage loadingPage">
    <div class="position">
      <i class="lee-icon lee-icon-arrow-left" @click="back"></i>
      <div class="title">Loading</div>
    </div>
    <div class="pageBody">
      <lee-cell-group title="加载类型" inset>
        <lee-loading/>
        <lee-loading type="circle-side"/>
        <lee-loading type="circle-arrow"/>
        <lee-loading type="rectangle"/>
        <lee-loading type="square-move"/>
        <lee-loading type="ball-circle"/>
        <lee-loading type="ball-wave"/>
        <lee-loading type="take-turn"/>
        <lee-loading type="ball-pulse"/>
        <lee-loading type="radar-2"/>
        <lee-loading type="radar-1"/>
        <lee-loading type="spinner"/>
        <lee-loading type="progress"/>
        <lee-loading type="zebra-crossing"/>
        <lee-loading type="flip-block"/>
        <lee-loading type="box"/>
        <lee-loading type="swim-block"/>
        <lee-loading type="croll-block"/>
        <lee-loading type="wave"/>
      </lee-cell-group>
      <lee-cell-group title="自定义颜色" inset>
        <lee-loading color="#fc0"/>
        <lee-loading type="spinner" color="#1989fa"/>
      </lee-cell-group>
      <lee-cell-group title="自定义大小" inset>
        <lee-loading :size="60"/>
        <lee-loading type="spinner" :size="50"/>
      </lee-cell-group>
      <lee-cell-group title="加载文案" inset>
        <lee-loading>加载中...</lee-loading>
      </lee-cell-group>
      <lee-cell-group title="垂直排列" inset>
        <lee-loading vertical>加载中...</lee-loading>
      </lee-cell-group>
      <lee-cell-group title="自定义文本颜色" inset>
        <lee-loading color="#fc0" vertical="" text-color="#fc0">加载中...</lee-loading>
        <lee-loading type="spinner" vertical="" color="#1989fa" text-color="#1989fa">加载中...</lee-loading>
      </lee-cell-group>
      <lee-cell-group title="自定义图标" inset>
        <lee-loading color="#fc0" vertical="" text-color="#fc0">
          <template #icon>
            <lee-icon name="sun" :size="30" color="#fc0"/>
          </template>
          加载中...
        </lee-loading>
        <lee-loading type="spinner" vertical="" color="#1989fa" text-color="#1989fa">
          <template #icon>
            <lee-icon name="favorite" :size="30" color="#1989fa"/>
          </template>
          加载中...</lee-loading>
      </lee-cell-group>
    </div>
    <div class="pageFooter"><img src="@/assets/logo.png"></div>
  </div>
</template>
<script setup>
import {useRouter} from 'vue-router'

const router = useRouter()
const back = () => {
    router.push('/')
}
</script>
<style>
.loadingPage .inset {
  background: var(--lee-white);
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
}

.loadingPage .lee-loading {
  margin-right: 6px;
  font-size: 30px;
}

.loadingPage .lee-cell-group:first-child .lee-loading {
  width: 25%;
  margin-right: 0;
  border: solid 1px var(--lee-gray-light);
  margin-left: -1px;
  margin-top: -1px;
  padding: 16px 0;
}
</style>
